import { memo, useCallback } from 'react'

import { Text } from '@cash-me/react-components'

import { Carousel } from 'components/carousel'
import { Grid, Col } from 'components/grid'
import useWindowSize from 'hooks/useWindowSize'
import { pushDataLayer } from 'utils/push-data-layer'

import { choiceList } from './data'
import * as S from './styles'

const WhyChoose = memo(() => {
  const { isDesktop } = useWindowSize()
  const handleDataLayer = useCallback((index: number) => {
    const cardTitle = choiceList[index].title

    pushDataLayer({
      category: 'Slider',
      action: 'Por que escolher o consórcio da CashMe?',
      label: `Slider - ${cardTitle}`
    })
  }, [])

  return (
    <S.Section>
      <Grid col={{ phone: 1, tablet: 12, desktop: 12 }}>
        <Col start={{ desktop: 2 }} col={{ tablet: 4, desktop: 4 }}>
          <Text as="h2" weight="medium" color="var(--colors-pink-text-default)">
            Por que escolher{' '}
            <Text as="span" weight="medium" color="var(--colors-primary-text-strong)">
              o consórcio da CashMe?
            </Text>
          </Text>
          <Text size="heading5" color="var(--colors-primary-text-strong)" css={{ paddingTop: 'var(--space-spacing-md)' }}>
            Vale a pena para você que:
          </Text>
        </Col>
        {isDesktop ? (
          <Col start={{ desktop: 6 }} col={{ tablet: 6, desktop: 6 }}>
            <S.WrapperCards>
              {choiceList.map(({ id, title, description }) => (
                <S.Card key={id}>
                  <Text as="h3" size="heading4" weight="bold" color="var(--colors-neutral-text-default)">
                    {title}
                  </Text>
                  <Text color="var(--colors-neutral-text-weaker)">
                    {description}
                  </Text>
                </S.Card>
              ))}
            </S.WrapperCards>
          </Col>
        ) : (
          <S.TextWrapper>
            <Carousel onChangeIndex={handleDataLayer} navigation={true}>
              {choiceList.map(({ id, title, description }) => (
                <S.CarouselItem key={id}>
                  <Text as="h3" className="name" size="heading3" weight="medium">
                    {title}
                  </Text>
                  <Text className="depoiment" color="var(--colors-neutral-text-weak)">
                    {description}
                  </Text>
                </S.CarouselItem>
              ))}
            </Carousel>
          </S.TextWrapper>
        )}
      </Grid>
    </S.Section>
  )
})

WhyChoose.displayName = 'WhyChoose'

export default WhyChoose
