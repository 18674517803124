/* eslint-disable max-len */
import styled from 'styled-components'

import { theme } from 'styles/theme'

const { colors, media, space, fontSizes } = theme

export const Section = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: ${colors['neutral-background-contrast']};
  padding-top: ${space['spacing-xxxl']};
  padding-bottom: ${space['spacing-xxxl']};

  h2, h2 > span {
    font-size: ${fontSizes['font-size-heading-3']};
  }

  @media (${media.desktop}) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: ${space['spacing-md']};

  @media (${media.desktop}) {
    h2 {
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const CarouselItem = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  gap: ${space['spacing-xxxs']};
  cursor: grab;

  .location {
    margin-bottom: ${space['spacing-xs']};
  }

  p.depoiment {
    padding-right: ${space['spacing-sm']};
  }

  @media (${media.desktop}) {
    p.name {
      font-size: ${fontSizes['font-size-heading-3']};
    }

    .location {
      font-size: ${fontSizes['font-size-body-1']};
    }
  }
`

export const WrapperCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${space['spacing-sm']};
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${space['spacing-xs']};
  background-color: white;
  border-radius: 12px;
  padding: ${space['spacing-sm']};
`
