export const choiceList = [
  {
    id: 1,
    title: 'Tem pressa',
    description: `O consórcio é para você que possui valor acumulado no FGTS ou uma reserva financeira. 
    Nessas condições, você pode ofertar lances que aumentam as chances de uma contemplação rápida.`
  },
  {
    id: 2,
    title: 'Não tem pressa',
    description: `Uma das grandes vantagens do consórcio é o planejamento, especialmente para quem não 
    tem pressa. Com prazos mais longos, as parcelas ficam mais acessíveis e não comprometem sua saúde 
    financeira. A compra ocorre sem juros e sem complicações.`
  },
  {
    id: 3,
    title: 'Quer investir',
    description: `O consórcio também é uma ótima opção para quem não tem um objetivo financeiro 
    específico, mas deseja aumentar seu patrimônio. O compromisso mensal, com custo acessível, pode 
    facilitar a conquista de uma aposentadoria imobiliária, por exemplo.`
  },
  {
    id: 4,
    title: 'Não quer financiar',
    description: `Ao contrário do financiamento, o consórcio não possui juros e representa uma opção de 
    pagamento à vista. Isso permite que você negocie valores mais vantajosos na aquisição do seu bem.`
  }
]
